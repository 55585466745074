.map {
  height: 480px;
  margin: 0;
  width: 100%;
  display: block;
  .infoMarker {
    text-align: left;
    color: #333;
  }
}

.mapContent {
  p {
    color: #000;
    margin: 5px 0;
  }
}
