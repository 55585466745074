.search{
  height: 100vh;
  max-height: 811px;
  border-bottom: 90px solid $green;

}

.subsearch{
  background: $green;
  padding: 70px 0 100px 0;
}

.innersearch{
  width: 90%;
  max-width: 916px;
  margin: 0 auto;
  h1{
    color: #fff;
    font-size: 35px;
    font-weight: $light;
    margin-bottom: 15px;
    @media (max-width: 800px) {
      font-size: 5vw;
    }
  }
  .input{
    width: 100%;
    display: block;
    background: #fff;
    height: 48px;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.1);
  }
  .search-form{
    width: 100%;
    display: block;
    height: 48px;
    box-shadow: 0 0 2px 0 rgba(0,0,0,0.1);
    @media (max-width: 800px) {
      width: 100%;
      display: block;
      height: auto;
      box-shadow: none;
    }
    form{
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      @media (max-width: 800px) {
        flex-wrap: wrap;
      }
      input{
        margin: 0 ;
        width: 40%;
        border: none;
        border-right: 1px solid #dadada;
        color: #8C999E;
        font-size: 14px;
        @media (max-width: 1000px) {
          width: 30%;
        }
        @media (max-width: 800px) {
          width: 100%;
          margin-bottom: 20px;
        }
      }
      .btn{
        width: 15%;
        border: none;
        background: $green;
        color: #fff;
        font-size: 14px;
        text-transform: uppercase;
        padding: 12px 0;
        @media (max-width: 1000px) {
          width: 17.5%;
        }
        @media (max-width: 800px) {
          width: 100%;
          margin-bottom: 20px;
        }
      }
    }
  }
  &.about-search{
    .search-form form .btn{
      background: $navy;
    }
  }

  &.job-page-search{
    width: 100%;
    max-width: 100%;
    margin-bottom: 50px;
    .search-form{
      box-shadow: none;
      form{
        input{
          background-color: $grey;
          @media (max-width: 800px) {
            border: 1px solid #dadada;
          }
        }
        .select-selected, .select-items {
          background-color: $grey;
        }
        .select-items {
          left: -1px;
          border-left: 1px solid #dadada;
          border-right: 1px solid #dadada;
          border-bottom: 1px solid #dadada;
          @media (max-width: 800px) {
            border: 1px solid #dadada;
          }
        }
      }
    }
  }
}
