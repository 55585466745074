footer {
  padding: 40px 0;
  P{
    margin: 0;
    @media (max-width: 800px) {
      font-size: 12px;
    }
  }
  .halfwidth{
    @media (max-width: 800px) {
      margin-bottom: 5vw;
    }
  }
}
