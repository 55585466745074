ul.pagination {
  display: block;
  margin: 0 -5px 80px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  li {
    display: inline-block;
    margin: 0 5px;
    a {
      display: block;
      &.secondary-btn {
        padding: 10px 0;
        font-size: 14px;
        width: 40px;
        height: 40px;
        text-align: center;
        background-color: #fff;
        color: $green;
        border: 1px solid $green;

      }
    }
    &.active {
      a {
        &.secondary-btn {
          color: #fff;
          background-color: $green;
        }
      }
    }
  }
}
