.dialogueforms{
  width: 100%;
  max-width: 400px;
  .form-item{
    width: 100%;
    input{
      width: 100%;
    }
  }
  .btn {
    margin-top: 30px;
  }

}
