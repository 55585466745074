h1,
h2,
h3,
h4 {
  font-family: $title-font-family;
  font-style: normal;
  font-weight: $light;
  margin-bottom: 29px;
}

p{
  font-family: $standard-font-family;
  font-size: 14px;
  color: $navy;
  letter-spacing: 0;
  line-height: 21px;
  margin-bottom: 29px;
  a{
    color: $navy;
    text-decoration: underline;
  }
}

.italic{
  font-style: italic;
}

.title{
  font-family: $title-font-family;
  font-size: 28px;
  font-weight: $regular;
  color: $green;
  letter-spacing: 0.1px;
  line-height: 37px;
  margin-bottom: 29px;
  @media (max-width: 1368px) {
    font-size: 2.2vw;
    line-height: 3.5vw;
  }
  @media (max-width: 800px) {
    font-size: 5vw;
    line-height: 6.5vw;
  }
  &.jobalerts{
    &::after {
      content: "";
      width: 28px;
      height: 28px;
      display: inline-block;
      margin-left: 17px;
      background-image: url("/assets/img/ico_jobalert.svg");
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  &.cv{
    &::after {
      content: "";
      width: 28px;
      height: 28px;
      display: inline-block;
      margin-left: 17px;
      background-image: url("/assets/img/ico_cv.svg");
      background-repeat: no-repeat;
      background-position: center;
    }
  }
  &.navy{
    color: $navy;
  }
}

.green-title{
  background: $green;
  color: #fff;
  padding: 14px 23px;
  width: fit-content;
  width: intrinsic;
  position: absolute;
  text-decoration: none;
  font-family: $title-font-family;
  font-size: 24px;
  font-weight: $regular;
  letter-spacing: 0.1px;
  z-index: 100;
  @media (max-width: 800px) {
    font-size: 4vw;

  }
}

.generalcontent{
  ul{
    margin-bottom: 30px;
    li{
      font-size: 14px;
      color:  $navy;
      letter-spacing: 0;
      line-height: 21px;
      margin-bottom: 10px;
      display: flex;
      &:before {
        content: "-";
        text-indent: -3px;
        margin-right: 10px;
      }
    }
  }
}

.whitecontent{
  ul{
    li{
      color: #fff;
    }
  }
  p, a, h1, h2, h3 {
    color: #fff;
  }
}

.smalltitle{
  font-family: $standard-font-family;
  font-weight: $semibold;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 21px;
  margin-bottom: 10px;
}

.smalllink{
  text-decoration: underline;
  float: right;
  color: $navy;
}

.generalcontentarea{
  padding: 40px 0;
  ul{
    margin-bottom: 30px;
    li{
      font-size: 14px;
      color:  $navy;
      letter-spacing: 0;
      line-height: 21px;
      margin-bottom: 10px;
      display: flex;
      &:before {
        content: "-";
        text-indent: -3px;
        margin-right: 10px;
      }
    }
  }
  h2, h3, h4 {
    font-family: $standard-font-family;
    font-weight: $semibold;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 21px;
    margin-bottom: 10px;
  }
}
