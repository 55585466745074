.filter{
  margin-bottom: 30px;
  @media (max-width: 1000px) {
    display: none;
  }
  h2{
    font-size: 23px;
    color: $navy;
    letter-spacing: 0.02px;
    margin-bottom: 5px;
    font-weight: $regular;
  }
  a{
    font-size: 14px;
    color: $navy;
    letter-spacing: 0.01px;
    width: 100%;
    display: block;
    margin-bottom: 5px;
    margin-bottom: 5px;
  }
}

.jobs{
  .latestjobs{
    background: $grey;
    a{
      h3{
        font-size: 24px;
        font-weight: $regular;
        color: $navy;
        letter-spacing: -0.17px;
        margin-bottom: 15px;
        border-top: 1px solid #dadada;
        padding-top: 15px;
      }
      &:hover{
        text-decoration: none;
      }
    }
    .details {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      @media (max-width: 940px) {
        flex-wrap: wrap;
      }
      h4{
        width: 30%;
        font-size: 14px;
        color: $green;
        font-weight: $semibold;
        letter-spacing: 0.1px;
        margin-bottom: 15px;
        @media (max-width: 1600px) {
          width: 100%;
        }
        @media (max-width: 800px) {
          width: 100%;
        }
      }
    }
    p{
      margin-bottom: 0;
      font-weight: $regular;
      width: 100%;
      strong{
        font-weight: $regular;
      }
    }
    .btn{
      margin-top: 20px;
    }


  }
  .btn{
    margin-top: 35px;
  }
  &.homejobs{
    background: $grey;
    padding: 80px 0;
    @media (max-width: 800px) {
      padding: 18% 0 10%;
    }
    .title{
      @media (max-width: 800px) {
        padding: 0 5%;
      }
    }
    .latestjobs{
      background: $grey;
      border-bottom: 1px solid #dadada;
      padding: 15px 0;
      @media (max-width: 800px) {
        padding: 8% 5% 11% 5%;
      }

      h3{
        border-top: none;
        padding-top: 0;
      }
      .details {
        h4{
          width: 24%;
          @media (max-width: 800px) {
            width: 100%;
          }
        }
      }
      &:nth-child(2) {
        border-top: 1px solid #dadada;
      }
    }
  }
}

.jobdetails{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 30px -15px;
  li{
    width: calc(50% - 30px);
    position:relative;
    margin: 0 15px;
    padding: 10px 0;
    font-size: 14px;
    display: flex;
    flex-wrap: nowrap;
    @media (max-width: 800px) {
      width: 100%;
    }
    h4{
      margin: 0;
      font-weight: $regular;
      a{
        color: $navy;
        text-decoration: underline;
      }
    }
    a{
      img{
        height: 18px;
        margin-left: 10px;
      }
    }
  }
}
