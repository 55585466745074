.testimonial-area{
  padding-top: 170px;
  padding-bottom: 80px;
  @media (max-width: 800px) {
    padding-top: 10vw;
    padding-bottom: 10vw;
  }
  .testimonials{
    .item{
      padding-right: 30px;
      font-weight: $regular;
      p{
        margin: 0;
      }
      h4{
        color: $green;
      }
    }
  }
  .paging {
    display: inline-block;
    height: 37px;
    width: 100%;
    position: relative;
    vertical-align: top;
    padding: 0 10px;
    ul.slick-dots {
      position: static;
      bottom: 0;
      @media (max-width: 800px) {
        text-align: left;
      }
      li {
        padding: 0;
        margin: 0 2.5px;
        height: 7px;
        width: 7px;
        display: inline-block;
        position: relative;
        &:active {
          top: 1px;
        }
        button {
          background: #C1C9CA;
          height: 7px;
          width: 7px;
          border-radius: 50%;
          overflow: hidden;
          padding: 0;
          margin: 0;
          &:before {
            display: none;
          }
        }
        &.slick-active {
          button {
            background: #3E4C52;
          }
        }
      }
    }
  }
}
