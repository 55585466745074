.search{
  height: 45.25vw;
  max-height: 811px;
  border-bottom: 90px solid $green;
  @media (max-width: 1000px) {
    height: 45.25vw;
    max-height: none;
    border-bottom: 10px solid $green;
  }
  @media (max-width: 800px) {
    height: 75.25vw;
    max-height: none;
    border-bottom: none;
  }
  @media (max-width: 590px) {
    height: 70vh;

  }
}

.homeintro{
  padding-bottom: 80px;
  @media (max-width: 1368px) {
    padding-bottom: 5.5vw;
  }
  @media (max-width: 1000px) {
    padding-bottom: 5.5vw;
    padding-top: 5.5vw;
  }
  @media (max-width: 800px) {
    padding-top: 0;
  }

  .intro{
    margin-top: 198px;
    padding-right: 85px;

    @media (max-width: 1368px) {
      margin-top: 11.5vw;
    }
    @media (max-width: 1000px) {
      margin-top: 0;
    }
    @media (max-width: 800px) {
      margin-top: 0;
      padding: 13% 5% 5% 5%;
      order: 2;
    }
  }

  .overlapintro{
    position: absolute;
    top: -224px;
    right: 0;
    height: fit-content;
    @media (max-width: 1368px) {
      top: -16vw;
    }
    @media (max-width: 1000px) {
      top: 0;
      position: relative;
    }
    @media (max-width: 800px) {

      order: 1;
    }
    .top{
      padding: 44px 64px 16px 64px;
      @media (max-width: 1368px) {
        padding: 4vw 4vw 1vw 4vw;
      }
      @media (max-width: 800px) {
        padding: 8vw 5vw 3vw 5vw;
      }
      h2{
        color: #fff;
        font-weight: $regular;
        font-size: 56px;
        letter-spacing: -2.23px;
        line-height: 62px;
        @media (max-width: 1368px) {
          font-size: 4vw;
        }
        @media (max-width: 1245px) {
          font-size: 3vw;
          line-height: 3.5vw;
        }
        @media (max-width: 800px) {
          font-size: 5vw;
        }
      }
      p{
        color: #fff;
      }
    }
    .btm{
      background: $green;
      padding-left: 64px;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      @media (max-width: 1368px) {
        padding-left: 4vw;
      }
      h3{
        color: #fff;
        font-weight: $regular;
        font-size: 34px;
        letter-spacing: -0.28px;
        width: 52%;
        margin: 0;
        padding: 20px 0;
        @media (max-width: 1368px) {
          font-size: 2vw;
        }
        @media (max-width: 800px) {
          font-size: 5vw;
        }
      }
      a{
        width: 24%;
        float: right;
        &.linkedin{
          background: $darkgreen;
        }
      }

    }
  }
}
