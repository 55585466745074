section.regular {
  position: relative;
}

.inner-wrap {
  max-width: $container + px;
  width: 90%;
  margin: auto;
  &.mobile-inner{
    @media (max-width: 800px) {
      width: 80%;
    }
  }
}

.banner{
  width: calc(100% - 60px);
  margin-left: 30px;
  margin-right: 30px;
  height: 45.2vw;
  max-height: 811px;
  background-repeat: no-repeat;
  background-size: cover;
  position: absolute;
  z-index: 1;
  background-color: $navy;
  @media (max-width: 1000px) {
    width: calc(100% - 10%);
    margin-left: 5%;
    margin-right: 5%;
  }
  &::before {
    content: "";
    width: 100%;
    height: 45.2vw;
    max-height: 811px;
    background-color: #000;
    position: absolute;
    opacity: .3;
  }
  &.homebanner{
    @media (max-width: 800px) {
      height: 75.2vw;
      max-height: none;
      &::before {
        height: 75.2vw;
      }
    }
    @media (max-width: 590px) {
      height: 70vh;
      max-height: none;
      &::before {
        height: 70vh;
      }
    }
  }
}


.page-mid {
  z-index: 2;
  width: 100%;
  position: relative;
  min-height: calc(100vh);
  @media (max-width: 800px) {
    min-height: auto;
  }
}

.fullwidth{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  @media (max-width: 800px) {
    flex-wrap: wrap;
  }
  .halfwidth{
    width: 50%;
    position:relative;
    @media (max-width: 800px) {
      width: 100%;
    }
  }
}

.jobsfullwidth{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  @media (max-width: 1000px) {
    flex-wrap: wrap;
  }

  .sub-nav{
    width: 30%;
    min-height: 200px;
    @media (max-width: 1000px) {
      width: 100%;
      min-height: 0;
    }
    h1{
      margin-bottom: 52px;
    }
  }
  .right-content{
    width: 70%;
    min-height: 200px;
    @media (max-width: 1000px) {
      width: 100%;
    }
  }
}

.lightareas-mobile{
  .halfwidth{
    @media (max-width: 800px) {
      img{
        width: 100%;
      }
    }
  }
}

.split{
  background: linear-gradient(90deg, $white 0%, $white 50.01%, $navy 50.01%, $navy 100%);
  position: relative;
  @media (max-width: 800px) {
    background: $grey;
  }
  &.grey-top{
    &::before {
      content: "";
      width: 50%;
      height: 64px;
      background-color: $grey;
      position: absolute;
      @media (max-width: 800px) {
        width: 100%;
      }
    }
  }
  .halfwidth{
    @media (max-width: 800px) {
      img{
        width: 100%;
      }
    }
  }
}

.navyhalf{
  @media (max-width: 800px) {
    background-color: $navy;
    padding: 8% 5%;
    &.mobilebutton{
      padding: 8% 5% 20%;
    }
  }
}

.centeritems{
  display:flex;
  justify-content:center;
  align-items:center;
}
.position-relative{
  position: relative;
}

.jobalerts-cv{
  padding:80px 0;
  @media (max-width: 800px) {
    padding: 20% 0 0 0;
  }
  .halfwidth{
    padding-right: 85px;
    @media (max-width: 800px) {
      padding: 0 5% 20% 5%;
    }
  }
}

.general-top-content{
  width: 90%;
  margin: 0 auto;
  padding-top: 27vw;
  @media (max-width: 800px) {
    width: 91%;
  }
  .general-inner{
    width: 100%;
    padding-top: 8vw;
    padding-bottom: 100px;
    background: #fff;
    @media (max-width: 800px) {
      padding-bottom: 8vw;
    }
    .halfwidth{
      &:first-child{
        padding-right: 80px;
        @media (max-width: 1200px) {
          padding-right: 4vw;
        }
        @media (max-width: 800px) {
          padding-right: 0;
        }
      }
    }
    &.jobspage{
      padding-top: 10vh;
      padding-bottom: 40px;
      @media (max-width: 1000px) {
        padding-top: 5vh;
      }
    }
  }
  &.small-top-padding{
    padding-top: 3%;
  }
}

.inner-dark{
  padding: 56px;
  @media (max-width: 800px) {
    padding: 0;
  }
}

.inner-light{
  padding: 70px 56px 30px 0;
  @media (max-width: 800px) {
    background: #fff;
    padding: 8% 5%;
    &.mobilebutton{
      padding: 8% 5% 20%;
    }
  }
}

.padding-title-top{
  padding-top: 130px;
  @media (max-width: 800px) {
    padding: 0;
  }
}

.candidates-cv-inner {
  padding: 116px 56px 36px 56px;
  @media (max-width: 800px) {
    padding: 0;
  }
}

.btm-left{
  bottom: 0;
  left: 0;
}
.btm-right{
  bottom: 0;
  right: 0;
  @media (max-width: 800px) {
    left:0;
  }
}

.top-left{
  top: 0;
  left: 0;
}
.top-right{
  top: 0;
  right: 0;
}
.borderbottom{
  border-bottom: 1px solid #dadada;
}
.bordertop{
  border-top: 1px solid #dadada;
  @media (max-width: 800px) {
    border-top: none;
  }
}


.pushimagedown{
  position: relative;
  top: 64px;
  @media (max-width: 800px) {
    top: 0;
  }
}
.paddingtop160{
  padding-top: 160px;
  @media (max-width: 800px) {
    padding-top: 10vw;
    padding-bottom: 10vw;
  }
}
.paddingtop80{
  padding-top: 80px;
}
.paddingbtm80{
  padding-bottom: 80px;
}
.padding30{
  padding: 30px;
}
.margintop160{
  margin-top: 160px;
  @media (max-width: 800px) {
   margin: 0;
  }
}
.margintop80{
  margin-top: 80px;
}
.marginbtm80{
  margin-bottom: 80px;
}
.marginbtm40{
  margin-bottom: 40px;
}
.position-relative{
  position: relative;
}

.float-left{
  float: left;
}

.float-right{
  float: right;
}

.d-flex{
  display: flex;
  @media (max-width: 600px) {
    flex-wrap: wrap;
    .title{
      width: 100%;
      font-size: 7vw;
    }
    .smalllink{
      width: 100%;
    }
  }
}
.flex-grow-1{
  flex-grow: 1;
}

.mobile-padding{
  @media (max-width: 800px) {
    padding-left:5%;
    padding-right:5%;
  }
}
@media (max-width: 800px) {
  .order1{
    order: 1;
  }
  .order2{
    order: 2;
  }
  .mobilepaddingcandidate{
    padding-top: 13vw;
  }
  .mobilepaddinginterviewadvice{
    padding: 17vw 5% 7% 5%;
    margin-bottom: 80px;
  }
}
