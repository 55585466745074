$title-font-family: "montserrat", sans-serif;
$standard-font-family: "montserrat", sans-serif;

$light: 300;
$regular: 400;
$medium: 500;
$semibold: 600;
$bold: 700;



$container: 1200;
$container_small: 1200;

$yellow: #ffe700;
$gold: $yellow;
$red: #b6051a;
$blue: #20609b;
$lightblue: #069afe;
$white: #fff;
$grey: #F1F1F1;
$navy: #36444A;
$green: #0C9B48;
$darkgreen: #008D43;

.bckwhite{background-color: $white;}
.bckgrey{background-color: $grey;}
.bcknavy{background-color: $navy;}
.green{color: $green;}
.navy{color: $navy;}

$break-small: 400px;
$break-large: 1130px;
$mobile-nav: 995px;
$max-width: 100%;

$imgpath: "../../../../assets/img/";
