.aboutcarousel {
  .ImageExpertise {
    .slick-list {
      overflow: unset;
    }
  }
  .ImageText {
    @media (max-width: 800px) {
      top: -10px;
    }
    .inner-dark {
      padding: 50px 0 0 50px;
      @media (max-width: 800px) {
        padding: 0;
      }
    }
  }
  .controls {
    position: absolute;
    left: 50%;
    z-index: 100;
    bottom: -51px;
    @media (max-width: 1223px) {
      bottom: 0;
    }
    @media (max-width: 800px) {
      bottom: 10px;
      right: 5%;
      left: auto;
    }
    .next,
    .prev {
      z-index: 100;
      width: 50px;
      height: 50px;
      padding: 10px;
      background: $green;
      cursor: pointer;
      @media (max-width: 1223px) {
        width: 30px;
        height: 30px;
        padding: 0;
      }
      @media (max-width: 800px) {
        width: 40px;
        height: 40px;
        padding: 5px;
      }
      &::after {
        content: "";
        width: 28px;
        height: 25px;
        display: inline-block;
        background-image: url(/assets/img/ico_arrow.svg);
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        background-size: 21px;
        @media (max-width: 1223px) {
          background-size: 10px;
        }
      }
    }
    .prev {
      transform: rotate(180deg);
    }
    .next {
      border-bottom: 1px solid #fff;
      @media (max-width: 1223px) {
        border-bottom: 1px solid $navy;
      }
    }
  }
}

.staff {
  padding-top: 170px;
  padding-bottom: 80px;
  @media (max-width: 800px) {
    padding-top: 15vw;
    padding-bottom: 8vw;
  }
  .halfwidth {
    padding-right: 80px;
    @media (max-width: 800px) {
      padding-right: 0;
      padding-bottom: 18vw;
    }
    img {
      max-width: 257px;
      margin-bottom: 30px;
      @media (max-width: 800px) {
        max-width: 100%;
        width: 100%;
      }
    }
    h3 {
      color: $green;
    }
  }
}

a {
  &.staff-linkedin {
    &.smalltitle {
      margin-left: 20px;
      color: #0c9b48;
    }
  }
}
