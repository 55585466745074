#mobile-nav-items {
  display: block;
  position: absolute;
  top: 0;
  right: -354px;
  max-width: 354px;
  width: 100vw;
  bottom: 0;
  height: 100%;
  z-index: 50;
  text-align: left;
  background: #d2d2d2;
  padding: 0;
  @media (max-width: 1040px) {
    right: 0;
  }
  #mobile-nav-inner-items{
    position: fixed;
    width: 370px;
  }
  .top {
    padding: 48px 30px;
    img {
      height: 40px;
    }
  }
  ul {
    padding: 0 60px;
    margin: 0;
    li {
      font-style: normal;
      font-weight: 400;
      display: block;
      text-align: left;
      font-size: 16px;
      border-bottom: 1px solid rgba(#979797, 0.2);
      font-family: $title-font-family;
      li {
        padding: 5px 0;
      }
      a,
      span {
        color: $navy;
        display: inline-block;
        line-height: 1.25em;
        padding: 14px 0;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
        i {
          display: inline-block;
          margin-left: 10px;
          position: relative;
          top: -3px;
        }
      }
      ul.second {
        display: none;
        margin: 5px 0 25px;
        padding: 0;
        li {
          border-bottom: 0;
          display: flex;
          padding: 0;
          text-transform: none;
          margin-bottom: 10px;
          span {
            padding: 0;
            margin-right: 8px;
          }
          a {
            padding: 0;
            font-size: 13px;
            display: inline-block;
          }
        }
      }
      ul.open {
        display: block;
      }
      &.active {
        color: #76a958;
        span {
          color: #76a958;
        }
        ul.second {
          display: block;
          li {
            a {
              color: #fff;
              &.active {
                text-decoration: underline;
              }
            }
          }
        }
        > a {
          color: #76a958;
        }
      }
    }
  }
}
