button {
  border: 0;
}
.btn {
  position: relative;
  display: inline-block;
  font-family: $title-font-family;
  font-weight: $regular;
  color: $green;
  padding: 15px;
  font-size: 17px;
  letter-spacing: 0.16px;
  border: 1px solid $green;
  padding: 9px 40px;

  i {
    margin-left: 10px;
    display: inline-block;
  }
  &:active {
    top: 1px;
  }
  &:hover,
  &:focus {
    color: $green;
    text-decoration: underline;
  }
  //&[href="#"],
  // &[href=""] {
  //   display: none;
  // }
}

.back-btn {
  display: inline-block;
}

.green-btn{
  background: $green;
  color: #fff;
  padding: 20px 23px;
  width: 50%;
  position: absolute;
  text-decoration: none;
  @media (max-width: 800px) {
    width: 65%;
  }
  &::after {
    content: "";
    width: 28px;
    height: 25px;
    display: inline-block;
    background-image: url(/assets/img/ico_arrow.svg);
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    right: 23px;
  }
  &:hover,
  &:focus {
    color: white;
    text-decoration: none;
  }
}
