header {
  background-color: white;
  font-family: $title-font-family;
  .top {
    padding: 55px 0 40px 0;
    .inner-wrap {
      display: flex;
      align-items: stretch;
      a.logo {
        img {
          max-width: 220px;
          @media (max-width: 932px) {
            max-width: 180px;
          }
        }
      }
      .col:nth-child(1) {
        flex-grow: 1;
      }
      .col:nth-child(2) {
        display: flex;
        align-items: flex-end;
        ul {
          display: flex;
          position: relative;
          top: 7px;
          @media (max-width: 900px) {
            display: none;
          }
          li {
            padding: 0 0 0 33px;
            @media (max-width: 1093px) {
              padding: 0 0 0 20px;
            }
            a {
              color: #3E4C51;
              font-weight: $medium;
              font-size: 15px;
              display: block;
              @media (max-width: 994px) {
                font-size: 14px;
              }
              @media (max-width: 950px) {
                font-size: 12px;
              }
              &.active {
                text-decoration: underline;
              }
            }
            &:last-child{
              a{
                color: $green;
              }
            }
          }

        }
      }
    }
  }
}

#mobile-burger-btn {
  cursor: pointer;
  display: none;
  height: 38px;
  margin: 0;
  text-align: center;
  transition: right 0.5s;
  width: 40px;
  z-index: 100;
  border-radius: 0;
  @media (max-width: 900px) {
    display: inline-block;
    position: fixed;
    right: 5%;
    top: 40px;
    z-index: 9999;
  }
  i {
    font-size: 28px;
    position: relative;
    top: 5px;
    &:nth-child(2) {
      display: none;
    }
  }
  &.menu-open {
    i {
      &:nth-child(1) {
        display: none;
      }
      &:nth-child(2) {
        display: inline-block;
      }
    }
  }
}
