form {
  width: 100%;
}
label {
  display: none;
  &.show {
    display: block;
    font-size: 24px;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: 800;
    margin-bottom: 24px;
  }
}

input,
textarea {
  border-radius: 0;
  margin: 10px 0;
  border: none;
  font-size: 16px;
  padding: 13px 25px;
  letter-spacing: 0;
  line-height: 1.35;
  color: #333;

  background: #ffffff;
  border: 1px solid #dfdfdf;
  border-radius: 0;

  &.required {
    border: 1px solid #8b0000;
  }

  &.form-control {
    border-radius: 0;
  }
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #7d7d7d;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #7d7d7d;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #7d7d7d;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #7d7d7d;
}
textarea {
  min-height: 234px;
}

input[type="text"],
input[type="email"],
input[type="password"],
textarea {
  -webkit-appearance: none;
}

button {
  width: 100%;
}

.dialog-item {
  button {
    margin-top: 10px;
  }
}

.validation-msg {
  padding: 20px 25px;
  color: #333;
  margin: 10px auto;
  text-align: center;
  color: #262626;
  border: 1px solid #e0d8d8;
  border-radius: 3px;
  line-height: 1;
  background: #ffffcc;
  &.error {
    background: #f7e0e0;
  }
  @media (max-width: 900px) {
    padding: 15px 20px;
  }
  @media (max-width: 600px) {
    padding: 10px 15px;
  }
  @media (max-width: 450px) {
    padding: 7.5px 10px;
  }
}

.select_style {
  overflow: hidden;
  display: inline-block;
  position: relative;
  cursor: pointer;
  background: white;
  border-radius: 0;
  font-family: $standard-font-family;
  font-size: 16px;
  width: 100%;
  position: relative;
  border: 1px solid #dfdfdf;
  margin-bottom: 0;
  color: #6f6f6f;
  padding: 13px 25px;
  @media (max-width: 900px) {
    font-size: 15px;
    line-height: 1em;
  }
  @media (max-width: 600px) {
    font-size: 14px;
    line-height: 1em;
  }
  @media (max-width: 450px) {
    font-size: 12px;
    line-height: 1em;
  }
  &:after {
    content: "\f078";
    font-family: "Font Awesome 5 Free";
    position: absolute;
    color: #909090;
    top: 12px;
    font-weight: 700;
    font-size: 14px;
    right: 15px;
    pointer-events: none;
  }
  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background: none;
    background: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    width: 100%;
    option {
      padding-right: 45px;
    }
  }
}

.error-input,
div.error-input {
  border: 1px $red solid;
}
.error-msg {
  font-size: 14px;
  padding: 5px 0;
  display: none;
  color: $red;
  text-align: right;
}

/* The container must be positioned relative: */
.custom-select {
  position: relative;
  width: 15%;
  border-right: 1px solid #dadada;
  color: #8c999e;
  font-size: 14px;
  @media (max-width: 1000px) {
    width: 17.5%;
  }
  @media (max-width: 800px) {
    width: 100%;
    margin-bottom: 20px;
    border: 1px solid #dadada;
  }
}

.no-right-border {
  border-right: none;
  @media (max-width: 800px) {
    border-right: 1px solid #dadada;
  }
}

.custom-select select {
  display: none; /*hide original SELECT element: */
}

.select-selected {
  background-color: #fff;
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 20px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #8c999e transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #8c999e transparent;
  top: 13px;
}

/* style the items (options), including the selected item: */
.select-items div,
.select-selected {
  padding: 12px 16px;
  border-top: 1px solid #dadada;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.select-selected {
  border-top: none;
}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: #fff;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  width: 238px;
  @media (max-width: 800px) {
    width: 100.4%;
  }
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}


.validation-msg-cv{
  background-color: #ffffcc;
  padding: 10px;
  text-align: center;
  margin-bottom: 40px;
  margin-top: -10px;;
}